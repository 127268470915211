// customFetch.js
'use strict';
import 'isomorphic-fetch';

import { customFetch as customFetchLog } from '../resource/debug.js';
import { reduxStoreInitializePromise } from '../resource/reduxStoreInitializePromise.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import { TOKEN_EXPIRED_THRESHOLD } from '../RemoteConfigKeys.js';

const log = customFetchLog.extend('log');
const errorLog = customFetchLog.extend('error');

let tokenRenewer = null;

/**
 * Set token renewer to customFetch
 * @param {function} {tokenRenewer} - function to pre-check and update header access token
 * @param {bool} {[shouldReplace = false]} - wheather should replace old renewer
 */
export const setTokenRenewer = ({
  tokenRenewer: inputTokenRenewer,
  shouldReplace = false,
}) => {
  if (shouldReplace || !tokenRenewer) {
    tokenRenewer = inputTokenRenewer;
    log('setTokenRenewer', { inputTokenRenewer, shouldReplace });
  }
};

/**
 * Create token renewer function to customFetch
 * @param {getState} {getState} - redux store getState
 * @param {dispatch} {dispatch} - redux store dispatch
 */
export const createTokenRenewer = ({ getState, dispatch }) => {
  log('createTokenRenewer()');
  let expiredThreshold;
  let processTokenPromise = null;

  const processToken = async ({ token }) => {
    log('processToken()', { token, dispatch, getState });
    const state = getState();
    const [
      { default: getExternalData },
      { default: getTimestampOffset, Accuracy },
      { default: getCurrentUnixTimestamp },
    ] = await Promise.all([
      import('../selector/getExternalData.js'),
      import('../selector/getTimestampOffset.js'),
      import('../resource/getCurrentUnixTimestamp.js'),
    ]);
    const loadGetRemoteConfigDataPromise = import(
      '../selector/getRemoteConfigData.js'
    );
    const loadDecodePromise = import('jwt-decode');

    const serverTimestamp = getExternalData(state, ['timestamp'], 'server');
    let timestampOffsetSeconds = null;
    if (serverTimestamp) {
      timestampOffsetSeconds = getTimestampOffset(getState(), Accuracy.SECOND);
    } else {
      log('processToken() fetch time');
      timestampOffsetSeconds =
        await window.__ntp__.asyncGetNtpTimestampOffset();
    }

    const now = getCurrentUnixTimestamp({
      offsetSeconds: timestampOffsetSeconds,
    });
    log('processToken()', { now });

    // get the current exp from the access token
    const decode = (await loadDecodePromise).jwtDecode;
    const decoded = decode(token);
    const currentExp = decoded.exp;
    if (!currentExp) {
      // the token won't expire
      return token;
    }

    const remainDuration = (currentExp - now) * 1000;
    log('processToken()', { currentExp, remainDuration });

    if (!expiredThreshold) {
      const getRemoteConfigData = (await loadGetRemoteConfigDataPromise)
        .default;

      expiredThreshold = getRemoteConfigData(state, TOKEN_EXPIRED_THRESHOLD);
    }
    log('processToken()', { expiredThreshold });

    if (remainDuration < expiredThreshold) {
      const { default: fetchAccessToken, viaTypes } = await import(
        '../action/fetchAccessToken.js'
      );
      if (remainDuration > 0) {
        // Token is about to expire, refresh in parallel
        log('processToken() refresh in parallel');
        dispatch(
          fetchAccessToken({
            triggerToken: token,
            via: viaTypes.CUSTOM_FETCH_PARALLEL,
          })
        );
      } else {
        // Token has expired, need to block fetch and refresh
        log('processToken() block fetch for refresh');
        const loadGetMeDataPromise = import('../selector/getMeData.js');
        const [{ default: getMeData }] = await Promise.all([
          loadGetMeDataPromise,
          dispatch(
            fetchAccessToken({
              triggerToken: token,
              via: 'customFetch-block',
            })
          ),
        ]);
        const newToken = getMeData(getState(), 'token');
        log('processToken()', { newToken });
        return newToken;
      }
    }

    return token;
  };

  return async ({ url, options }) => {
    log('tokenRenewer()', { url, options, dispatch, getState });
    const result = { url, options };

    try {
      const token = options.headers
        .get('Authorization')
        .match(/^Bearer (\S+)$/)?.[1];
      log('tokenRenewer()', { token });

      if (token) {
        let newToken = token;
        if (!processTokenPromise) {
          log('tokenRenewer() new process token promise');
          processTokenPromise = processToken({ token });
          newToken = await processTokenPromise;
          processTokenPromise = null;
        } else {
          log('tokenRenewer() reuse process token promise');
          newToken = await processTokenPromise;
        }
        log('tokenRenewer()', { token, newToken });

        if (token !== newToken) {
          options.headers.set('Authorization', `Bearer ${newToken}`);
          log('tokenRenewer() option replaced');
        }
      }
    } catch (error) {
      errorLog('tokenRenewer()', { error });
    }

    return result;
  };
};

/**
 * Custom fetch
 * @param {string} url - regular url param for native fetch.
 * @param {object} options - regular options param for native fetch.
 */
const customFetch = async (inputUrl, inputOptions) => {
  let url = inputUrl;
  let options = inputOptions;
  const shouldUseCustomFetch = inputOptions?.shouldUseCustomFetch !== false;

  if (!shouldUseCustomFetch) {
    log('custom fetch bypassed');

    return fetch(url, options);
  }

  if (options?.headers) {
    // input may be object style or Headers object
    const headers = new Headers(options?.headers);
    options.headers = headers;
  }

  log('init', {
    inputUrl,
    inputOptions,
    options,
  });

  for (let i = 0; i < customFetch.usePreProcessRequest.length; i += 1) {
    const preProcess = customFetch.usePreProcessRequest[i];
    const result = await preProcess({ url, options });

    url = result.url;
    options = result.options;
  }

  return fetch(url, options);
};

const checkXPrefixHeader = async ({ url, options }) => {
  // only for api.swag.live
  try {
    const fullUrl = new URL(url);
    if (fullUrl.hostname !== 'api.swag.live') return { url, options };
    // eslint-disable-next-line no-empty
  } catch (_) {}

  if (
    options?.headers &&
    (!options.headers.has('x-client-id') || !options.headers.has('x-track'))
  ) {
    // for Next.js, we need to wait for the redux store is initialized
    await reduxStoreInitializePromise;

    options.headers = new Headers({
      ...Object.fromEntries(options.headers),
      ...getHeaders(),
    });
  }

  return { url, options };
};

const checkAuthorizationHeader = async ({ url, options }) => {
  // for *.swag.live
  try {
    const fullUrl = new URL(url);
    if (!/swag.live$/gi.test(fullUrl.hostname)) return { url, options };
    // eslint-disable-next-line no-empty
  } catch (_) {}

  const authorizationHeader = options?.headers?.get('Authorization');

  log('init', {
    options,
    tokenRenewer,
    authorizationHeader,
  });

  if (tokenRenewer && authorizationHeader) {
    const { url: newUrl, options: newOptions } = await tokenRenewer({
      url,
      options,
    });

    log('processed', { url: newUrl, options: newOptions });
    return { url: newUrl, options: newOptions };
  }
  return { url, options };
};

customFetch.usePreProcessRequest = [
  checkXPrefixHeader,
  checkAuthorizationHeader,
];

export default customFetch;
