// messages.js
'use strict';
import { fromJS } from 'immutable';
import {
  ADD_MESSAGE,
  ADD_MESSAGES,
  LOGOUT,
  REMOVE_MESSAGES,
  CLEAR_ALL_MESSAGES,
  SET_MESSAGE,
  CHANGE_MESSAGE_ID,
  // -- PLOP_PREPEND_REDUCER_ACTION_TYPE --
} from '../ActionTypes.js';

export const defaultState = fromJS({});
export const MessageMergeBehavior = {
  OVERRIDE: 'override',
  KEEP_OLD: 'keepOld',
};

const keepOldMerger = previous => previous;

/**
 * All messages, includes broadcast/chat/reply messages.
 * @module reducer/messages
 */
const messages = (state = defaultState, action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return _ADD_MESSAGE({
        // TODO: change to action.payload.message
        message: action.payload,
        behavior: action.behavior,
      })(state);
    case ADD_MESSAGES:
      return _ADD_MESSAGES({
        messages: action.payload.messages,
        behavior: action.behavior,
      })(state);
    case LOGOUT:
      return _LOGOUT(action.payload)(state);
    case REMOVE_MESSAGES:
      return _REMOVE_MESSAGES(action.payload)(state);
    case CLEAR_ALL_MESSAGES:
      return _CLEAR_ALL_MESSAGES(action.payload)(state);
    case SET_MESSAGE:
      return _SET_MESSAGE(action.payload)(state);
    case CHANGE_MESSAGE_ID:
      return _CHANGE_MESSAGE_ID(action.payload)(state);
    // -- PLOP_PREPEND_REDUCER_SWITCH_CASE --
    default:
      return state;
  }
};

/**
 * Add message
 * @kind reducer/actionType
 * @name ADD_MESSAGE
 * @param {string} {behavior} - either OVERRIDE or KEEP_OLD, default OVERRIDE
 * @param {object} {message} - message
 * @param {string} {message.id} - message id
 * @return {Immutable.Map} New state
 */
const _ADD_MESSAGE =
  ({ message, behavior = MessageMergeBehavior.OVERRIDE }) =>
  state => {
    if (MessageMergeBehavior.KEEP_OLD === behavior) {
      return state.mergeDeepWith(keepOldMerger, { [message.id]: message });
    }

    // immutable.js merge an array like an object here,
    // that's why we clear categories first when it needs to be updated
    let _state = state;
    if (message.categories) {
      _state = _state.setIn([message.id, 'categories'], fromJS([]));
    }

    return _state.mergeDeep({ [message.id]: message });
  };

/**
 * Add messages
 * @kind reducer/actionType
 * @name ADD_MESSAGES
 * @param {string} {behavior} - either OVERRIDE or KEEP_OLD, default OVERRIDE
 * @param {object} {messages} - messages using message id as keys
 * @return {Immutable.Map} New state
 */
const _ADD_MESSAGES =
  ({ messages, behavior = MessageMergeBehavior.OVERRIDE }) =>
  state => {
    if (MessageMergeBehavior.KEEP_OLD === behavior) {
      return state.mergeDeepWith(keepOldMerger, messages);
    }

    // immutable.js merge an array like an object here,
    // that's why we clear categories first when it needs to be updated
    let _state = state;
    Object.values(messages).forEach(message => {
      if (message.categories) {
        _state = _state.setIn([message.id, 'categories'], fromJS([]));
      }
    });

    return _state.mergeDeep(messages);
  };

/**
 * Logout
 * @kind reducer/actionType
 * @name LOGOUT
 * @return {Immutable.Map} New state
 */
const _LOGOUT = () => () => {
  return defaultState;
};

/**
 * Remove messages
 * @kind reducer/actionType
 * @name REMOVE_MESSAGES
 * @param {string} { [itemIds = []] } - message ids.
 * @return {Immutable.Map} New state
 */
const _REMOVE_MESSAGES =
  ({ itemIds = [] }) =>
  state => {
    return itemIds.reduce((acc, id) => acc.delete(id), state);
  };

/**
 * Clear all messages
 * @kind reducer/actionType
 * @name CLEAR_ALL_MESSAGES
 * @return {Immutable.Map} New state
 */
const _CLEAR_ALL_MESSAGES = () => () => {
  return defaultState;
};

/**
 * Set message
 * @kind reducer/actionType
 * @name SET_MESSAGE
 * @param {string} {selectPath} - select path.
 * @param {object} {data} - message data.
 * @return {Immutable.Map} New state
 */
const _SET_MESSAGE =
  ({ selectPath, data }) =>
  state => {
    return state.setIn(selectPath, fromJS(data));
  };

/**
 * Change message id
 * @kind reducer/actionType
 * @name CHANGE_MESSAGE_ID
 * @param {object} payload - payload
 * @return {Immutable.Map} New state
 */
const _CHANGE_MESSAGE_ID =
  ({ oldId, newId } = {}) =>
  state => {
    return state
      .mergeDeepIn([newId], state.get(oldId).set('id', newId))
      .delete(oldId);
  };

// -- PLOP_PREPEND_REDUCER_ACTION_HANDLER --

export default messages;
