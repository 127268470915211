// i18nCommon.js
'use strict';
import env from './env.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';

export const LANGUAGE_QUERY_NAME = 'lang';

export const localeConvertor = ({ locale, isISO639 = false }) => {
  let lng = typeof locale === 'string' ? locale : locale?.[0];
  switch (lng?.toLowerCase?.()) {
    case 'zh':
    case 'zh-cn':
    case 'zh-hans':
    case 'zh-hans-cn':
      return isISO639 ? 'zh' : 'zh-CN';
    case 'ja-jp':
      return 'ja';
    case 'en':
    case 'en-us':
      return 'en';
    default:
      if (lng?.startsWith?.('zh-')) {
        return isISO639 ? 'zh-hant' : 'zh-TW';
      }
      if (lng && /^[a-zA-Z]{2,3}(-[a-zA-Z0-9]{0,5}){0,2}-*$/.exec(lng)) {
        return lng.replace(/-*$/, '');
      }
      return 'en';
  }
};

export const i18nCommonOption = {
  fallbackLng: code => {
    const language = code?.toLowerCase?.();
    if (language?.startsWith?.('zh-')) {
      return ['zh-TW', 'en'];
    } else {
      return ['en'];
    }
  },
  interpolation: { prefix: '{', suffix: '}', skipOnVariables: false },
  debug: env.DEBUG_I18N === 'true',
  fallbackNS: TranslationNamespace.DEFAULT,
};

export const i18CommonHttpBackendOption = {
  loadPath: locale => localeConvertor({ locale }), // passing i18n locale.
  parse: data => data, // to ignore `i18next-http-backend` JSON.parse
};
